import React, { useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import { BiCheck, BiCopy } from 'react-icons/bi';
import copyToClipboard from '../../utils/copyToClipboard';
import defaultTheme from '../../theme/defaultTheme';

interface CopiaEColaTextProps {
  text?: string;
}

const Text = styled.span`
  font-weight: normal;
  font-size: 0.8em;
  max-width: 100%;
  overflow: hidden;
  display: inline-block;
  word-wrap: break-word;

  @media (${defaultTheme.mobile}) {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 80%;
    display: inline-block;
  }
`;

const Wrapper = styled.div`
  padding-right: 90px;
  position: relative;
  display: flex;

  @media (${defaultTheme.mobile}) {
    align-itens: center;
    display: flex;
  }
`;

const IconWra = styled.button`
  z-index: 2;
  background: none;
  border: 1px solid #ddd;
  padding: 0.5rem 0.5rem;
  border: 0;
  cursor: pointer;
  border-radius: 4px;
  display: flex;
  position: absolute;
  right: 0;
  top: 0;
  align-items: center;
  justify-content: center;

  svg {
    position: relative;
    top: -2px;
    margin-right: 0.4rem;
  }

  &:hover {
    background: #002b3c;
  }

  @media (${defaultTheme.mobile}) {
    svg {
      display: none;
    }
    background: #002b3c;
    color: white;
  }
`;

const CopiaEColaText: React.FC<CopiaEColaTextProps> = ({ text }) => {
  const [clicked, setClicked] = useState<boolean>(false);

  let timeout: NodeJS.Timeout | undefined;

  const reducedText = useMemo(() => {
    return text?.slice(0, 110) + '...';
  }, [text]);

  useEffect(() => {
    return () => {
      if (timeout) {
        clearTimeout(timeout);
      }
    };
  }, []);

  return (
    <Wrapper
      role="button"
      title="Clique para copiar"
      onClick={() => {
        if (text) {
          setClicked(true);

          setTimeout(function () {
            setClicked(false);
          }, 3000);

          copyToClipboard(text);
        }
      }}
    >
      <Text>{reducedText}</Text>
      <IconWra>
        {clicked ? <BiCheck size={20} /> : <BiCopy size={20} />}
        {clicked ? <span>Copiado</span> : <span>Copiar</span>}
      </IconWra>
    </Wrapper>
  );
};

export default CopiaEColaText;
