import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import styled from 'styled-components';
import BeatLoader from 'react-spinners/BeatLoader';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
// import PixIcon from '../common/PixIcon';
import ButtonStyled from '../common/Button';
import Alert from '../common/Alert';
import onlyNumbers from '../../utils/onlyNumbers';
import { cpfMask } from '../../utils/cpfMask';

interface BasicFormData {
  name?: string;
  cpf?: string;
  email?: string;
  paymentMethod: 'PIX' | 'TEF';
}

interface BasicFormProps {
  isSubmitting: boolean;
  showAlert?: boolean;
  data?: BasicFormData;
  onSubmitBasicForm: (data: BasicFormData) => void;
}

const FieldWrapper = styled.div`
  margin: 0 0 1rem 0;
  padding-bottom: 1.4rem;
  position: relative;

  label {
  }

  input {
    padding: 1rem;
    height: auto;
    width: 100%;
    display: block;
    border-radius: 5px;
    border: 1px solid #9f9f9f;
    font-size: inherit;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);

    &:focus {
      outline: 0 none;
      border-color: black;
    }

    &.error {
      box-shadow: 0 4px 10px rgba(255, 0, 0, 0.1);
      border-color: red;
    }
  }
`;

const TextLabel = styled.label`
  margin-bottom: 0.2rem;
  display: block;
  font-size: 0.9em;
`;

const ErrorMessage = styled.span`
  display: block;
  color: red;
  font-size: 0.8em;
  position: absolute;
  bottom: 0;
  right: 0;
  padding: 0.2rem;
`;

// const LabelRad = styled.label`
//   background: #f9f9f9;
//   border-radius: 4px;
//   margin: 0.4rem 0.4rem 0rem 0;
//   padding: 0.65rem 1.25rem;
//   padding-left: 1rem;
//   display: inline-block;
//   border: 1px solid #049056;
//   position: relative;
//   cursor: pointer;
//   box-shadow: 0 1px 1px hsl(0deg 0% 0% / 0.035), 0 2px 2px hsl(0deg 0% 0% / 0.035), 0 4px 4px hsl(0deg 0% 0% / 0.035),
//     0 8px 8px hsl(0deg 0% 0% / 0.035), 0 16px 16px hsl(0deg 0% 0% / 0.035);
//   display: inline-block;

//   input {
//     cursor: pointer;
//     position: absolute;
//     left: 0;
//     top: 0;
//     opacity: 0;
//     z-index: 3;
//   }

//   img {
//     margin-right: 0.3rem;
//   }
// `;

const ButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

const SubmitButton = styled(ButtonStyled)`
  max-width: 350px;
`;

const schema = yup
  .object({
    name: yup.string().required('Digite seu nome'),
    email: yup.string().email('Digite um e-mail válido').required('Digite seu e-mail'),
    cpf: yup.string().required('Digite seu CPF')
  })
  .required();

const BasicForm: React.FC<BasicFormProps> = ({ data, showAlert, isSubmitting, onSubmitBasicForm }) => {
  const [document, setDocument] = useState<string>(data?.cpf || '');
  const {
    handleSubmit,
    formState: { errors },
    register,
    setValue
  } = useForm<BasicFormData>({
    resolver: yupResolver(schema),
    defaultValues: {
      ...data
    }
  });

  useEffect(() => {
    setValue('name', data?.name);
    setValue('email', data?.email);
    setValue('cpf', data?.cpf);

    setDocument(data?.cpf || '');
  }, [data]);

  function preparePayload(data: BasicFormData) {
    onSubmitBasicForm({
      paymentMethod: data.paymentMethod,
      name: data.name?.trim(),
      email: data.email?.toLowerCase().trim(),
      cpf: onlyNumbers(document.toLowerCase().trim())
    });
  }

  function handleDocumentChange(e: React.FormEvent<HTMLInputElement>) {
    setDocument(e.currentTarget.value);
  }
  return (
    <form onSubmit={handleSubmit((data: BasicFormData) => preparePayload(data))}>
      {showAlert ? (
        <Alert type="info">Realize o pagamento utilizando uma conta bancária com CPF de mesma titularidade</Alert>
      ) : undefined}

      {/* <FieldWrapper className=''>
            <TextLabel>Forma de pagamento: </TextLabel>
            <div>
                <LabelRad>
                    <input name='paymentMethod' type='radio' />
                    <PixIcon />
                    Pix
                </LabelRad>
            </div>
        </FieldWrapper> */}

      <FieldWrapper className="">
        <TextLabel>Nome: </TextLabel>
        <input className={errors.name ? 'error' : ''} type="text" {...register('name')} />
        {errors.name && <ErrorMessage>{errors.name?.message}</ErrorMessage>}
      </FieldWrapper>

      <FieldWrapper className="">
        <TextLabel>E-mail: </TextLabel>
        <input className={errors.email ? 'error' : ''} type="email" {...register('email')} />
        {errors.name && <ErrorMessage>{errors.email?.message}</ErrorMessage>}
      </FieldWrapper>

      <FieldWrapper className="">
        <TextLabel>CPF: </TextLabel>
        <input
          className={errors.cpf ? 'error' : ''}
          type="tel"
          {...register('cpf')}
          value={cpfMask(document)}
          onChange={(e) => handleDocumentChange(e)}
        />
        {errors.name && <ErrorMessage>{errors.cpf?.message}</ErrorMessage>}
      </FieldWrapper>

      <ButtonWrapper>
        <SubmitButton id="safepag-form-submit-action" type="submit" disabled={isSubmitting}>
          {isSubmitting ? <BeatLoader size={9} color="white" /> : 'Pagar com Pix'}
        </SubmitButton>
      </ButtonWrapper>
    </form>
  );
};

export default BasicForm;
