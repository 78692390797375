import React from 'react';
import styled from 'styled-components';
import defaultTheme from '../../../theme/defaultTheme';
import loadAsset from '../../../utils/loadAsset';

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: flex-start;
  color: ${defaultTheme.primary};

  @media (${defaultTheme.mobile}) {
    padding: 0 2rem;
  }
`;

const Title = styled.h1`
  font-size: 2em;
  margin-bottom: 3rem;
`;

const Text = styled.p`
  font-size: 1em;
  margin: 0;

  margin-bottom: 1.2rem;

  &:last-child {
    margin-bottom: 0;
  }

  @media (${defaultTheme.mobile}) {
    margin-left: -105px;
    padding-top: 25px;
  }
`;

const Box = styled.div`
  width: 50%;
  padding: 1.2rem 0;
  align-items: center;

  @media (${defaultTheme.mobile}) {
    width: 70%;
  }
`;

const IconLeft = styled.div`
  width: 50%;
  padding: 0 2rem;

  @media (${defaultTheme.mobile}) {
    width: 30%;
    margin-bottom: 9.6rem;
    padding: 0;
  }
`;

const Image = styled.img`
  height: auto;
  display: block;
  max-width: 100%;

  @media (${defaultTheme.mobile}) {
    width: 60px;
  }
`;

const ErrorContainer = styled.div`
    margin-top: 4rem;
`;

function Error() {
  return (

    <ErrorContainer className="container">
        <Wrapper>
        <IconLeft>
            <Image src={loadAsset('images/broken-qrcode.png')} alt="Erro ao gerar QR Code" />
        </IconLeft>
      <Box>
        <Title>Ooops!</Title>

        <Text>Ocorreu um erro ao gerar o seu QR Code! </Text>
        <Text>Preencha novamente seus dados para que possamos gerar um novo código para você</Text>
      </Box>
    </Wrapper>
    </ErrorContainer>

    );
}

export default Error;
