import React from 'react';
import { Box } from 'rebass';
import styled from 'styled-components';
import loadAsset from '../../utils/loadAsset';

const Img = styled.img`
  display: inline-block;
  max-width: 100px;
  vertical-align: middle;
`;

const Text = styled.span`
  display: inline-block;
  vertical-align: middle;
  font-weight: 300;
  font-size: 0.9em;
  margin-right: 0.4rem;
`;

const Wrapper = styled.span`
  
  .box-logos {
    display: flex;
    margin-bottom: 1rem;
  }
  .box-pix {
    display: flex;
    flex-direction: space-between;
    margin-right: 35px;
  }
`;

export default function SafepagLogo() {
  return (
    <Wrapper>
      <Box className="box-logos">
        <Box className="box-pix">
          <Img src={loadAsset('images/pix2.png')} alt="Pix Logo" />
        </Box>
        <Box>
          <Text>Powered by</Text>
          <Img src={loadAsset('images/safepag-logo.png')} alt="Safepag Brand Logo" />
        </Box>
      </Box>
    </Wrapper>
  );
}
