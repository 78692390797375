import React from 'react';
import styled from 'styled-components';
import Resume from '../../common/Resume';
import BasicForm from '../../set/BasicForm';
import Alert from '../../common/Alert';
import defaultTheme from '../../../theme/defaultTheme';

export interface FormData {
  customerName?: string;
  customerEmail?: string;
  customerDocument?: string;
}

interface VersionA_PaymentFormProps {
  paymentParams: {
    merchantName: string;
    paymentDescription?: string;
    shipping: string | undefined;
    tax: string | undefined;
    total: string;
  };
  formData: FormData;
  isSubmitting: boolean;
  onSubmitForm: (data: FormData) => void;
}

const Wrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

const FormWrapper = styled.div`
  width: 60%;
  padding: 0 2rem;
  padding-left: 0;

  @media (${defaultTheme.mobile}) {
    width: 100%;
    padding 2rem;
  }
`;

const ResumeWrapper = styled.div`
  width: 40%;
  padding: 0 1.2rem;
  padding-right: 0;

  @media (${defaultTheme.mobile}) {
    display: none;
  }
`;

const ResumeWrapperMobile = styled.div`
    display: none;
    
    @media (${defaultTheme.mobile}) {
        display: block;
        width: 100%;
        padding 2rem 2rem 0rem 2rem;
    }


`;

export default function PaymentFormVersionA({
  isSubmitting,
  onSubmitForm,
  formData,
  paymentParams
}: VersionA_PaymentFormProps) {
  return (
    <>
      <Wrapper>
        <ResumeWrapperMobile>
          <Resume
            total={paymentParams.total}
            paymentDescriptionInfo={paymentParams.paymentDescription}
            merchantName={paymentParams.merchantName}
          />
        </ResumeWrapperMobile>
        <FormWrapper>
          <BasicForm
            onSubmitBasicForm={(data) => {
              onSubmitForm({
                customerDocument: data.cpf,
                customerName: data.name,
                customerEmail: data.email
              });
            }}
            showAlert={false}
            isSubmitting={isSubmitting}
            data={{
              name: formData.customerName,
              email: formData.customerEmail,
              cpf: formData.customerDocument,
              paymentMethod: 'PIX'
            }}
          />
        </FormWrapper>
        <ResumeWrapper>
          <Resume
            total={paymentParams.total}
            paymentDescriptionInfo={paymentParams.paymentDescription}
            merchantName={paymentParams.merchantName}
          />
          <Alert>Realize o pagamento utilizando uma conta com CPF de mesma titularidade</Alert>
        </ResumeWrapper>
        <ResumeWrapperMobile>
          <Alert>Realize o pagamento utilizando uma conta com CPF de mesma titularidade</Alert>
        </ResumeWrapperMobile>
      </Wrapper>
    </>
  );
}
