import React from 'react';
import { Route, Routes } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';
import { LangProvider } from './contexts/LangContext';
import { CheckoutProvider } from './contexts/CheckoutContext';
import Home from './pages/Home';
import RedirectCheckoutWrapper from './components/layout/RedirectCheckoutWrapper';
import defaultTheme from './theme/defaultTheme';
import GlobalStyle from './theme/globalStyle';
import PaymentFormV1 from './pages/v1/PaymentFormV1';
import QRCodeV1 from './pages/v1/QRCodeV1';
import QRCodeV2 from './pages/v2/QRCodeV2';
import ErrorV2 from './pages/v2/ErrorV2';
import { GlobalContextProvider } from './contexts/GlobalContext';
import PaymentFormV2 from './pages/v2/PaymentFormV2';
import Error from "./components/layout/a/Error";

export const App = () => (
  <LangProvider>
    <CheckoutProvider>
      <GlobalContextProvider>
        <ThemeProvider theme={defaultTheme}>
          <GlobalStyle />
          <Routes>
            <Route path='*' element={<Home />} />

            <Route element={<RedirectCheckoutWrapper />}>
              <Route path='/direct/deposit/:apiKey/:jwt' element={<PaymentFormV1 />} />
              <Route path='/direct/deposit/:apiKey/:jwt/qr' element={<QRCodeV1 />} />
            </Route>

            <Route element={<RedirectCheckoutWrapper />}>
              <Route path='/v2' element={<QRCodeV2 />} />
              <Route path='/v2/:guid/:hash' element={<PaymentFormV2 />} />
              <Route path='/v2/:guid/:hash/qr' element={<QRCodeV2 />} />

              <Route path='/v2/:guid/:hash/error' element={<ErrorV2 />} />
              <Route path='/v2/error/:guid' element={<ErrorV2 />} />
              <Route path='/v2/error' element={<ErrorV2 />} />

              <Route path='/error' element={<Error />} />
            </Route>
          </Routes>
        </ThemeProvider>
      </GlobalContextProvider>
    </CheckoutProvider>
  </LangProvider>
);
