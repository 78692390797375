import React from 'react';
import styled from 'styled-components';
import formatCurrency from '../../utils/formatCurrency';
import defaultTheme from '../../theme/defaultTheme';

const Wrapper = styled.div`
  margin-bottom: 2rem;
`;

const Box = styled.div`
  border: 1px solid #dedddd;
  font-size: 0.9em;
  color: #3d404c;
  padding: 2rem;

  dl {
    margin: 0;
    padding-bottom: 2rem;
  }

  th,
  dt {
    font-weight: 300;
    font-size: 0.9em;
    margin: 0 0 0.3rem 0;
    text-align: left;
  }

  td,
  dd {
    margin: 0 0 1.2rem 0;
    padding: 0;
    font-weight: bold;
    font-size: 1.2em;
    &:last-child {
      margin: 0;
    }
  }

  table {
    width: 100%;
    margin-bottom: 1rem;

    th,
    td {
      border: 0;
      padding: 0.5rem 0;
      margin: 0;
    }

    td {
      margin: 0;
      text-align: right;
    }
  }
  @media (${defaultTheme.mobile}) {
    display: flex;
    justify-content: space-between;
    align-itens: center;
    padding: 1rem;

    dl {
      padding: 0rem;

      dd {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        width: 95%;
        display: inline-block;
      }
    }
    .merchan-description {
      display: none;
    }
  }
`;

const Total = styled.div`
  color: #049056;
  font-size: 1.8em;
  letter-spacing: -0.015em;
  text-align: right;
  font-weight: 800;
  padding: 1rem 0 0;
  border-top: 1px solid #dedddd;

  @media (${defaultTheme.mobile}) {
    border-top: none;
    padding: 0rem;
    display: flex;
    align-items: center;
  }
`;

const Line = styled.div`
  border-top: 1px solid #dedddd;
`;

interface Props {
  merchantName: string;
  paymentDescriptionInfo?: string;
  tax?: string;
  total: string;
  shipping?: string;
}

const Resume: React.FC<Props> = ({ paymentDescriptionInfo, merchantName, total, tax, shipping }: Props) => {
  return (
    <Wrapper>
      <Box>
        <dl>
          <dt className="merchan-description">Comerciante</dt>
          <dd className="merchan-description">{merchantName}</dd>
          {paymentDescriptionInfo != '' ? (
            <>
              <dt>Pagamento de</dt>
              <dd>{paymentDescriptionInfo}</dd>
            </>
          ) : undefined}
        </dl>

        {Boolean(tax && shipping) ? (
          <>
            <Line />
            <table>
              <tbody>
                <tr>
                  <th>Shipping</th>
                  <td>{formatCurrency(shipping)}</td>
                </tr>
                <tr>
                  <th>Tax</th>
                  <td>{formatCurrency(tax)}</td>
                </tr>
              </tbody>
            </table>
          </>
        ) : undefined}

        <Total title="Total: ">{formatCurrency(total)}</Total>
      </Box>
    </Wrapper>
  );
};
export default Resume;
