import { Button, Dialog, TextInputField } from "evergreen-ui";
import { useForm } from "react-hook-form";
import urlJoin from "url-join";
import config from "../../services/config";

interface Props {
    show: boolean;
    onClose: () => void;
}

interface Fields {
    jwt: string;
    apiKey: string;
}

export default function SetupModalV1(props: Props) {
    const {
        handleSubmit,
        register,
        formState: { errors },
    } = useForm<Fields>({
    });

    function openForm(data: Fields) {
        window.open(urlJoin(config.checkoutUiUrl, '/direct/deposit/', data.apiKey, data.jwt), '_blank');
    }

    return <Dialog
        title='Redirect Checkout Safepag V1 (JWT)'
        hasFooter={false}
        onCancel={() => props.onClose()}
        isShown={props.show}
        onCloseComplete={() => props.onClose()}
        >

        <form autoComplete='off' onSubmit={handleSubmit(data => openForm(data))}>

            <TextInputField
                label='ApiKey'
                placeholder='apiKey'
                {...register('apiKey', { required: true })}
                validationMessage={errors.apiKey?.message}
            />
            <TextInputField
                label='JWT'
                type='text'
                placeholder='jwt'
                validationMessage={errors.jwt?.message}
                {...register('jwt', { required: true })}
            />

            <Button type='submit' marginBottom={32} appearance='primary'>Go To Checkout</Button>
        </form>
    </Dialog>
}
