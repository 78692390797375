import React from 'react';
import styled from 'styled-components';
import defaultTheme from '../../theme/defaultTheme';
import SafepagLogo from './SafepagLogo';

const FooterWrapper = styled.div`
  padding: 2rem 0rem;
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 50px;

  @media (${defaultTheme.mobile}) {
    padding: 0 2rem;
    justify-content: center;
  }
`;

const FlowRight = styled.div`
  // margin-left: auto;
`;

const Footer: React.FC = () => {
  return (
    <FooterWrapper className="container">
      <FlowRight>
        <SafepagLogo />
      </FlowRight>
    </FooterWrapper>
  );
};

export default Footer;
